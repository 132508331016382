// hotfix to be removed later when refactoring/removing ThemeProvider from shakra, legacy design system
body {
  background-color: #fff !important;
}

.red-bg > td {
  background-color: #f5e6e6;
}

.ant-table-tbody > tr.red-bg:hover > td {
  background: #efd7d7;
}

.red-bg .ant-table-column-sort {
  background-color: #efd7d7;
}

.green-bg > td {
  background-color: #e6f5ec;
}

.ant-table-tbody > tr.green-bg:hover > td {
  background: #d7efe1;
}

.green-bg .ant-table-column-sort {
  background-color: #d7efe1;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ant-btn {
  display: flex;
  align-items: center;
}

.ant-modal-footer,
.ant-modal-confirm-btns {
  display: flex;
  justify-content: flex-end;
}

.ant-collapse-header {
  display: flex;
  align-items: baseline;
}

@font-face {
  font-family: 'TTNormsPro-Regular';
  src: url('./assets/webfonts/TTNormsPro-Regular.eot');
  src:
    url('./assets/webfonts/TTNormsPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/webfonts/TTNormsPro-Regular.woff2') format('woff2'),
    url('./assets/webfonts/TTNormsPro-Regular.woff') format('woff'),
    url('./assets/webfonts/TTNormsPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TTNormsPro-Bold';
  src: url('./assets/webfonts/TTNormsPro-Bold.eot');
  src:
    url('./assets/webfonts/TTNormsPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/webfonts/TTNormsPro-Bold.woff2') format('woff2'),
    url('./assets/webfonts/TTNormsPro-Bold.woff') format('woff'),
    url('./assets/webfonts/TTNormsPro-Bold.ttf') format('truetype');
}

@primary-color: #38a169;@zindex-modal: 900;@zindex-modal-mask: 900;